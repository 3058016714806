/*=============================================
=                Placeholders                 =
=============================================*/

/*----------  Clearfix  ----------*/

%clearfix {
  content: "";
  display: table;
  clear: both;
}
