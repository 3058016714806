/*=============================================
=              Variables - Text               =
=============================================*/

// Fonts
$font: 'NeutraText-Book', sans-serif;
$mono: 'Butler', monospace;

// Text colors
$text-black: $black;
$text-grey: $grey;
$text-white: $white;

// Font Weights
$regular: 400;

