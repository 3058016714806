/*=============================================
=              Helpers - Spacing              =
=============================================*/

/*----------  Margin Top  ----------*/

// grid
.h-mt-grid { margin-top: $grid-gutter-width !important; }
.h-mt-grid\@xs { @include breakpoint($xs) { margin-top: $grid-gutter-width !important; } }
.h-mt-grid\@sm { @include breakpoint($sm) { margin-top: $grid-gutter-width !important; } }
.h-mt-grid\@md { @include breakpoint($md) { margin-top: $grid-gutter-width !important; } }
.h-mt-grid\@lg { @include breakpoint($lg) { margin-top: $grid-gutter-width !important; } }
.h-mt-grid\@xl { @include breakpoint($xl) { margin-top: $grid-gutter-width !important; } }

// tiny
.h-mt-tiny { margin-top: $padding-tiny !important; }
.h-mt-tiny\@xs { @include breakpoint($xs) { margin-top: $padding-tiny !important; } }
.h-mt-tiny\@sm { @include breakpoint($sm) { margin-top: $padding-tiny !important; } }
.h-mt-tiny\@md { @include breakpoint($md) { margin-top: $padding-tiny !important; } }
.h-mt-tiny\@lg { @include breakpoint($lg) { margin-top: $padding-tiny !important; } }
.h-mt-tiny\@xl { @include breakpoint($xl) { margin-top: $padding-tiny !important; } }

// xxs
.h-mt-xxs { margin-top: $padding-xxs !important; }
.h-mt-xxs\@xs { @include breakpoint($xs) { margin-top: $padding-xxs !important; } }
.h-mt-xxs\@sm { @include breakpoint($sm) { margin-top: $padding-xxs !important; } }
.h-mt-xxs\@md { @include breakpoint($md) { margin-top: $padding-xxs !important; } }
.h-mt-xxs\@lg { @include breakpoint($lg) { margin-top: $padding-xxs !important; } }
.h-mt-xxs\@xl { @include breakpoint($xl) { margin-top: $padding-xxs !important; } }

// xs
.h-mt-xs { margin-top: $padding-xs !important; }
.h-mt-xs\@xs { @include breakpoint($xs) { margin-top: $padding-xs !important; } }
.h-mt-xs\@sm { @include breakpoint($sm) { margin-top: $padding-xs !important; } }
.h-mt-xs\@md { @include breakpoint($md) { margin-top: $padding-xs !important; } }
.h-mt-xs\@lg { @include breakpoint($lg) { margin-top: $padding-xs !important; } }
.h-mt-xs\@xl { @include breakpoint($xl) { margin-top: $padding-xs !important; } }

// sm
.h-mt-sm { margin-top: $padding-sm !important; }
.h-mt-sm\@xs { @include breakpoint($xs) { margin-top: $padding-sm !important; } }
.h-mt-sm\@sm { @include breakpoint($sm) { margin-top: $padding-sm !important; } }
.h-mt-sm\@md { @include breakpoint($md) { margin-top: $padding-sm !important; } }
.h-mt-sm\@lg { @include breakpoint($lg) { margin-top: $padding-sm !important; } }
.h-mt-sm\@xl { @include breakpoint($xl) { margin-top: $padding-sm !important; } }

// md
.h-mt-md { margin-top: $padding-md !important; }
.h-mt-md\@xs { @include breakpoint($xs) { margin-top: $padding-md !important; } }
.h-mt-md\@sm { @include breakpoint($sm) { margin-top: $padding-md !important; } }
.h-mt-md\@md { @include breakpoint($md) { margin-top: $padding-md !important; } }
.h-mt-md\@lg { @include breakpoint($lg) { margin-top: $padding-md !important; } }
.h-mt-md\@xl { @include breakpoint($xl) { margin-top: $padding-md !important; } }

// lg
.h-mt-lg { margin-top: $padding-lg !important; }
.h-mt-lg\@xs { @include breakpoint($xs) { margin-top: $padding-lg !important; } }
.h-mt-lg\@sm { @include breakpoint($sm) { margin-top: $padding-lg !important; } }
.h-mt-lg\@md { @include breakpoint($md) { margin-top: $padding-lg !important; } }
.h-mt-lg\@lg { @include breakpoint($lg) { margin-top: $padding-lg !important; } }
.h-mt-lg\@xl { @include breakpoint($xl) { margin-top: $padding-lg !important; } }

// xl
.h-mt-xl { margin-top: $padding-xl !important; }
.h-mt-xl\@xs { @include breakpoint($xs) { margin-top: $padding-xl !important; } }
.h-mt-xl\@sm { @include breakpoint($sm) { margin-top: $padding-xl !important; } }
.h-mt-xl\@md { @include breakpoint($md) { margin-top: $padding-xl !important; } }
.h-mt-xl\@lg { @include breakpoint($lg) { margin-top: $padding-xl !important; } }
.h-mt-xl\@xl { @include breakpoint($xl) { margin-top: $padding-xl !important; } }

// xxl
.h-mt-xxl { margin-top: $padding-xxl !important; }
.h-mt-xxl\@xs { @include breakpoint($xs) { margin-top: $padding-xxl !important; } }
.h-mt-xxl\@sm { @include breakpoint($sm) { margin-top: $padding-xxl !important; } }
.h-mt-xxl\@md { @include breakpoint($md) { margin-top: $padding-xxl !important; } }
.h-mt-xxl\@lg { @include breakpoint($lg) { margin-top: $padding-xxl !important; } }
.h-mt-xxl\@xl { @include breakpoint($xl) { margin-top: $padding-xxl !important; } }

// None
.h-mt-none { margin-top: 0 !important; }
.h-mt-none\@xs { @include breakpoint($xs) { margin-top: 0 !important; } }
.h-mt-none\@sm { @include breakpoint($sm) { margin-top: 0 !important; } }
.h-mt-none\@md { @include breakpoint($md) { margin-top: 0 !important; } }
.h-mt-none\@lg { @include breakpoint($lg) { margin-top: 0 !important; } }
.h-mt-none\@xl { @include breakpoint($xl) { margin-top: 0 !important; } }

/*----------  Margin Bottom  ----------*/

// grid
.h-mb-grid { margin-bottom: $grid-gutter-width !important; }
.h-mb-grid\@xs { @include breakpoint($xs) { margin-bottom: $grid-gutter-width !important; } }
.h-mb-grid\@sm { @include breakpoint($sm) { margin-bottom: $grid-gutter-width !important; } }
.h-mb-grid\@md { @include breakpoint($md) { margin-bottom: $grid-gutter-width !important; } }
.h-mb-grid\@lg { @include breakpoint($lg) { margin-bottom: $grid-gutter-width !important; } }
.h-mb-grid\@xl { @include breakpoint($xl) { margin-bottom: $grid-gutter-width !important; } }

// tiny
.h-mb-tiny { margin-bottom: $padding-tiny !important; }
.h-mb-tiny\@xs { @include breakpoint($xs) { margin-bottom: $padding-tiny !important; } }
.h-mb-tiny\@sm { @include breakpoint($sm) { margin-bottom: $padding-tiny !important; } }
.h-mb-tiny\@md { @include breakpoint($md) { margin-bottom: $padding-tiny !important; } }
.h-mb-tiny\@lg { @include breakpoint($lg) { margin-bottom: $padding-tiny !important; } }
.h-mb-tiny\@xl { @include breakpoint($xl) { margin-bottom: $padding-tiny !important; } }

// xxs
.h-mb-xxs { margin-bottom: $padding-xxs !important; }
.h-mb-xxs\@xs { @include breakpoint($xs) { margin-bottom: $padding-xxs !important; } }
.h-mb-xxs\@sm { @include breakpoint($sm) { margin-bottom: $padding-xxs !important; } }
.h-mb-xxs\@md { @include breakpoint($md) { margin-bottom: $padding-xxs !important; } }
.h-mb-xxs\@lg { @include breakpoint($lg) { margin-bottom: $padding-xxs !important; } }
.h-mb-xxs\@xl { @include breakpoint($xl) { margin-bottom: $padding-xxs !important; } }

// xs
.h-mb-xs { margin-bottom: $padding-xs !important; }
.h-mb-xs\@xs { @include breakpoint($xs) { margin-bottom: $padding-xs !important; } }
.h-mb-xs\@sm { @include breakpoint($sm) { margin-bottom: $padding-xs !important; } }
.h-mb-xs\@md { @include breakpoint($md) { margin-bottom: $padding-xs !important; } }
.h-mb-xs\@lg { @include breakpoint($lg) { margin-bottom: $padding-xs !important; } }
.h-mb-xs\@xl { @include breakpoint($xl) { margin-bottom: $padding-xs !important; } }

// sm
.h-mb-sm { margin-bottom: $padding-sm !important; }
.h-mb-sm\@xs { @include breakpoint($xs) { margin-bottom: $padding-sm !important; } }
.h-mb-sm\@sm { @include breakpoint($sm) { margin-bottom: $padding-sm !important; } }
.h-mb-sm\@md { @include breakpoint($md) { margin-bottom: $padding-sm !important; } }
.h-mb-sm\@lg { @include breakpoint($lg) { margin-bottom: $padding-sm !important; } }
.h-mb-sm\@xl { @include breakpoint($xl) { margin-bottom: $padding-sm !important; } }

// md
.h-mb-md { margin-bottom: $padding-md !important; }
.h-mb-md\@xs { @include breakpoint($xs) { margin-bottom: $padding-md !important; } }
.h-mb-md\@sm { @include breakpoint($sm) { margin-bottom: $padding-md !important; } }
.h-mb-md\@md { @include breakpoint($md) { margin-bottom: $padding-md !important; } }
.h-mb-md\@lg { @include breakpoint($lg) { margin-bottom: $padding-md !important; } }
.h-mb-md\@xl { @include breakpoint($xl) { margin-bottom: $padding-md !important; } }

// lg
.h-mb-lg { margin-bottom: $padding-lg !important; }
.h-mb-lg\@xs { @include breakpoint($xs) { margin-bottom: $padding-lg !important; } }
.h-mb-lg\@sm { @include breakpoint($sm) { margin-bottom: $padding-lg !important; } }
.h-mb-lg\@md { @include breakpoint($md) { margin-bottom: $padding-lg !important; } }
.h-mb-lg\@lg { @include breakpoint($lg) { margin-bottom: $padding-lg !important; } }
.h-mb-lg\@xl { @include breakpoint($xl) { margin-bottom: $padding-lg !important; } }

// xl
.h-mb-xl { margin-bottom: $padding-xl !important; }
.h-mb-xl\@xs { @include breakpoint($xs) { margin-bottom: $padding-xl !important; } }
.h-mb-xl\@sm { @include breakpoint($sm) { margin-bottom: $padding-xl !important; } }
.h-mb-xl\@md { @include breakpoint($md) { margin-bottom: $padding-xl !important; } }
.h-mb-xl\@lg { @include breakpoint($lg) { margin-bottom: $padding-xl !important; } }
.h-mb-xl\@xl { @include breakpoint($xl) { margin-bottom: $padding-xl !important; } }

// xxl
.h-mb-xxl { margin-bottom: $padding-xxl !important; }
.h-mb-xxl\@xs { @include breakpoint($xs) { margin-bottom: $padding-xxl !important; } }
.h-mb-xxl\@sm { @include breakpoint($sm) { margin-bottom: $padding-xxl !important; } }
.h-mb-xxl\@md { @include breakpoint($md) { margin-bottom: $padding-xxl !important; } }
.h-mb-xxl\@lg { @include breakpoint($lg) { margin-bottom: $padding-xxl !important; } }
.h-mb-xxl\@xl { @include breakpoint($xl) { margin-bottom: $padding-xxl !important; } }

// None
.h-mb-none { margin-bottom: 0 !important; }
.h-mb-none\@xs { @include breakpoint($xs) { margin-bottom: 0 !important; } }
.h-mb-none\@sm { @include breakpoint($sm) { margin-bottom: 0 !important; } }
.h-mb-none\@md { @include breakpoint($md) { margin-bottom: 0 !important; } }
.h-mb-none\@lg { @include breakpoint($lg) { margin-bottom: 0 !important; } }
.h-mb-none\@xl { @include breakpoint($xl) { margin-bottom: 0 !important; } }

/*----------  Padding Top  ----------*/

// tiny
.h-pt-tiny { padding-top: $padding-tiny !important; }
.h-pt-tiny\@xs { @include breakpoint($xs) { padding-top: $padding-tiny !important; } }
.h-pt-tiny\@sm { @include breakpoint($sm) { padding-top: $padding-tiny !important; } }
.h-pt-tiny\@md { @include breakpoint($md) { padding-top: $padding-tiny !important; } }
.h-pt-tiny\@lg { @include breakpoint($lg) { padding-top: $padding-tiny !important; } }
.h-pt-tiny\@xl { @include breakpoint($xl) { padding-top: $padding-tiny !important; } }

// xxs
.h-pt-xxs { padding-top: $padding-xxs !important; }
.h-pt-xxs\@xs { @include breakpoint($xs) { padding-top: $padding-xxs !important; } }
.h-pt-xxs\@sm { @include breakpoint($sm) { padding-top: $padding-xxs !important; } }
.h-pt-xxs\@md { @include breakpoint($md) { padding-top: $padding-xxs !important; } }
.h-pt-xxs\@lg { @include breakpoint($lg) { padding-top: $padding-xxs !important; } }
.h-pt-xxs\@xl { @include breakpoint($xl) { padding-top: $padding-xxs !important; } }

// xs
.h-pt-xs { padding-top: $padding-xs !important; }
.h-pt-xs\@xs { @include breakpoint($xs) { padding-top: $padding-xs !important; } }
.h-pt-xs\@sm { @include breakpoint($sm) { padding-top: $padding-xs !important; } }
.h-pt-xs\@md { @include breakpoint($md) { padding-top: $padding-xs !important; } }
.h-pt-xs\@lg { @include breakpoint($lg) { padding-top: $padding-xs !important; } }
.h-pt-xs\@xl { @include breakpoint($xl) { padding-top: $padding-xs !important; } }

// sm
.h-pt-sm { padding-top: $padding-sm !important; }
.h-pt-sm\@xs { @include breakpoint($xs) { padding-top: $padding-sm !important; } }
.h-pt-sm\@sm { @include breakpoint($sm) { padding-top: $padding-sm !important; } }
.h-pt-sm\@md { @include breakpoint($md) { padding-top: $padding-sm !important; } }
.h-pt-sm\@lg { @include breakpoint($lg) { padding-top: $padding-sm !important; } }
.h-pt-sm\@xl { @include breakpoint($xl) { padding-top: $padding-sm !important; } }

// md
.h-pt-md { padding-top: $padding-md !important; }
.h-pt-md\@xs { @include breakpoint($xs) { padding-top: $padding-md !important; } }
.h-pt-md\@sm { @include breakpoint($sm) { padding-top: $padding-md !important; } }
.h-pt-md\@md { @include breakpoint($md) { padding-top: $padding-md !important; } }
.h-pt-md\@lg { @include breakpoint($lg) { padding-top: $padding-md !important; } }
.h-pt-md\@xl { @include breakpoint($xl) { padding-top: $padding-md !important; } }

// lg
.h-pt-lg { padding-top: $padding-lg !important; }
.h-pt-lg\@xs { @include breakpoint($xs) { padding-top: $padding-lg !important; } }
.h-pt-lg\@sm { @include breakpoint($sm) { padding-top: $padding-lg !important; } }
.h-pt-lg\@md { @include breakpoint($md) { padding-top: $padding-lg !important; } }
.h-pt-lg\@lg { @include breakpoint($lg) { padding-top: $padding-lg !important; } }
.h-pt-lg\@xl { @include breakpoint($xl) { padding-top: $padding-lg !important; } }

// xl
.h-pt-xl { padding-top: $padding-xl !important; }
.h-pt-xl\@xs { @include breakpoint($xs) { padding-top: $padding-xl !important; } }
.h-pt-xl\@sm { @include breakpoint($sm) { padding-top: $padding-xl !important; } }
.h-pt-xl\@md { @include breakpoint($md) { padding-top: $padding-xl !important; } }
.h-pt-xl\@lg { @include breakpoint($lg) { padding-top: $padding-xl !important; } }
.h-pt-xl\@xl { @include breakpoint($xl) { padding-top: $padding-xl !important; } }

// xxl
.h-pt-xxl { padding-top: $padding-xxl !important; }
.h-pt-xxl\@xs { @include breakpoint($xs) { padding-top: $padding-xxl !important; } }
.h-pt-xxl\@sm { @include breakpoint($sm) { padding-top: $padding-xxl !important; } }
.h-pt-xxl\@md { @include breakpoint($md) { padding-top: $padding-xxl !important; } }
.h-pt-xxl\@lg { @include breakpoint($lg) { padding-top: $padding-xxl !important; } }
.h-pt-xxl\@xl { @include breakpoint($xl) { padding-top: $padding-xxl !important; } }

// None
.h-pt-none { padding-top: 0 !important; }
.h-pt-none\@xs { @include breakpoint($xs) { padding-top: 0 !important; } }
.h-pt-none\@sm { @include breakpoint($sm) { padding-top: 0 !important; } }
.h-pt-none\@md { @include breakpoint($md) { padding-top: 0 !important; } }
.h-pt-none\@lg { @include breakpoint($lg) { padding-top: 0 !important; } }
.h-pt-none\@xl { @include breakpoint($xl) { padding-top: 0 !important; } }

/*----------  Padding Bottom  ----------*/

// tiny
.h-pb-tiny { padding-bottom: $padding-tiny !important; }
.h-pb-tiny\@xs { @include breakpoint($xs) { padding-bottom: $padding-tiny !important; } }
.h-pb-tiny\@sm { @include breakpoint($sm) { padding-bottom: $padding-tiny !important; } }
.h-pb-tiny\@md { @include breakpoint($md) { padding-bottom: $padding-tiny !important; } }
.h-pb-tiny\@lg { @include breakpoint($lg) { padding-bottom: $padding-tiny !important; } }
.h-pb-tiny\@xl { @include breakpoint($xl) { padding-bottom: $padding-tiny !important; } }

// xxs
.h-pb-xxs { padding-bottom: $padding-xxs !important; }
.h-pb-xxs\@xs { @include breakpoint($xs) { padding-bottom: $padding-xxs !important; } }
.h-pb-xxs\@sm { @include breakpoint($sm) { padding-bottom: $padding-xxs !important; } }
.h-pb-xxs\@md { @include breakpoint($md) { padding-bottom: $padding-xxs !important; } }
.h-pb-xxs\@lg { @include breakpoint($lg) { padding-bottom: $padding-xxs !important; } }
.h-pb-xxs\@xl { @include breakpoint($xl) { padding-bottom: $padding-xxs !important; } }

// xs
.h-pb-xs { padding-bottom: $padding-xs !important; }
.h-pb-xs\@xs { @include breakpoint($xs) { padding-bottom: $padding-xs !important; } }
.h-pb-xs\@sm { @include breakpoint($sm) { padding-bottom: $padding-xs !important; } }
.h-pb-xs\@md { @include breakpoint($md) { padding-bottom: $padding-xs !important; } }
.h-pb-xs\@lg { @include breakpoint($lg) { padding-bottom: $padding-xs !important; } }
.h-pb-xs\@xl { @include breakpoint($xl) { padding-bottom: $padding-xs !important; } }

// sm
.h-pb-sm { padding-bottom: $padding-sm !important; }
.h-pb-sm\@xs { @include breakpoint($xs) { padding-bottom: $padding-sm !important; } }
.h-pb-sm\@sm { @include breakpoint($sm) { padding-bottom: $padding-sm !important; } }
.h-pb-sm\@md { @include breakpoint($md) { padding-bottom: $padding-sm !important; } }
.h-pb-sm\@lg { @include breakpoint($lg) { padding-bottom: $padding-sm !important; } }
.h-pb-sm\@xl { @include breakpoint($xl) { padding-bottom: $padding-sm !important; } }

// md
.h-pb-md { padding-bottom: $padding-md !important; }
.h-pb-md\@xs { @include breakpoint($xs) { padding-bottom: $padding-md !important; } }
.h-pb-md\@sm { @include breakpoint($sm) { padding-bottom: $padding-md !important; } }
.h-pb-md\@md { @include breakpoint($md) { padding-bottom: $padding-md !important; } }
.h-pb-md\@lg { @include breakpoint($lg) { padding-bottom: $padding-md !important; } }
.h-pb-md\@xl { @include breakpoint($xl) { padding-bottom: $padding-md !important; } }

// lg
.h-pb-lg { padding-bottom: $padding-lg !important; }
.h-pb-lg\@xs { @include breakpoint($xs) { padding-bottom: $padding-lg !important; } }
.h-pb-lg\@sm { @include breakpoint($sm) { padding-bottom: $padding-lg !important; } }
.h-pb-lg\@md { @include breakpoint($md) { padding-bottom: $padding-lg !important; } }
.h-pb-lg\@lg { @include breakpoint($lg) { padding-bottom: $padding-lg !important; } }
.h-pb-lg\@xl { @include breakpoint($xl) { padding-bottom: $padding-lg !important; } }

// xl
.h-pb-xl { padding-bottom: $padding-xl !important; }
.h-pb-xl\@xs { @include breakpoint($xs) { padding-bottom: $padding-xl !important; } }
.h-pb-xl\@sm { @include breakpoint($sm) { padding-bottom: $padding-xl !important; } }
.h-pb-xl\@md { @include breakpoint($md) { padding-bottom: $padding-xl !important; } }
.h-pb-xl\@lg { @include breakpoint($lg) { padding-bottom: $padding-xl !important; } }
.h-pb-xl\@xl { @include breakpoint($xl) { padding-bottom: $padding-xl !important; } }

// xxl
.h-pb-xxl { padding-bottom: $padding-xxl !important; }
.h-pb-xxl\@xs { @include breakpoint($xs) { padding-bottom: $padding-xxl !important; } }
.h-pb-xxl\@sm { @include breakpoint($sm) { padding-bottom: $padding-xxl !important; } }
.h-pb-xxl\@md { @include breakpoint($md) { padding-bottom: $padding-xxl !important; } }
.h-pb-xxl\@lg { @include breakpoint($lg) { padding-bottom: $padding-xxl !important; } }
.h-pb-xxl\@xl { @include breakpoint($xl) { padding-bottom: $padding-xxl !important; } }

// None
.h-pb-none { padding-bottom: 0 !important; }
.h-pb-none\@xs { @include breakpoint($xs) { padding-bottom: 0 !important; } }
.h-pb-none\@sm { @include breakpoint($sm) { padding-bottom: 0 !important; } }
.h-pb-none\@md { @include breakpoint($md) { padding-bottom: 0 !important; } }
.h-pb-none\@lg { @include breakpoint($lg) { padding-bottom: 0 !important; } }
.h-pb-none\@xl { @include breakpoint($xl) { padding-bottom: 0 !important; } }
