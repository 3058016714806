/*=============================================
=                Layout - Base                =
=============================================*/

html {
  font-size: 10px;
  @include breakpoint($xxl) { font-size: 12px; }
}

body {
  font-family: $font;
  font-weight: $regular;
  color: $text-black;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $light-grey;
  background-color: $light-grey;
  overflow: hidden;
}

a {
  color: $light-grey;
}

.l-wrapper {
  margin: $headerHeight 0;
  height: calc(100vh - #{$headerHeight + $footerHeight}) !important;
  position: relative;
  overflow: hidden;

  @include breakpoint($md) {
    margin-left: $layoutGutter;
    margin-right: $layoutGutter;
  }
}

.l-main {
  height: calc(100vh - #{$headerHeight + $footerHeight}) !important;
}
