/*=============================================
=                   Buttons                   =
=============================================*/

.a-btn {
  font-family: $font;
  font-size: 1.1rem;
  letter-spacing: 1.83px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  color: $black;
  border: 1px solid $black;
  padding: 1.1rem 3rem;
  outline: 0;
  transition: color $time $easing, background-color $time $easing;

  &.is-active,
  &:hover {
    color: $light-grey;
    background-color: $black;
  }
}
