/*=============================================
=                Reset Inputs                 =
=============================================*/

input,
label,
select,
button,
textarea{
  margin:0;
	border:0;
	padding:0;
	display:inline-block;
	vertical-align:middle;
	white-space:normal;
	background:none;
	line-height:1;
}

input:focus {
	outline:0;
}
select:focus {
	outline:0;
}

input,
textarea {
	-webkit-box-sizing:content-box;
	-moz-box-sizing:content-box;
	box-sizing:content-box;
}

button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
	/* Fix IE7 display bug */
	overflow:visible;
	width:auto;
}
