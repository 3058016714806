.l-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  transition: bottom .5s $easing;

  &.is-visible {
    bottom: 0;
  }
}

.l-footer__inner {
  margin: 0 3rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-footer__logo {
  display: block;

  img {
    width: auto;
    max-height: 5rem;
  }
}
