/*=============================================
=            Pattern Scaffolding              =
=============================================*/

#sg-patterns {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  max-width: 100%;
  padding: 0 0.5em;
}

.sg-colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.sg-colors li {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  padding: 0.3em;
  margin: 0 0.5em 0.5em 0;
  min-width: 5em;
  max-width: 14em;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.sg-swatch {
  display: block;
  height: 4em;
  margin-bottom: 0.3em;
  border-radius: 5px;
}

.sg-label {
  font-size: 90%;
  line-height: 1;
}
