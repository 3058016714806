/*=============================================
=                    Fonts                    =
=============================================*/

// @font-face {
//   font-family: 'NeutraText-Demi';
//   font-display: swap;
//   src: url('../fonts/2579f054933cbd9045e00cce79f95178.eot'); /* IE9*/
//   src: url('../fonts/2579f054933cbd9045e00cce79f95178.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/2579f054933cbd9045e00cce79f95178.woff2') format('woff2'), /* chrome、firefox */
//        url('../fonts/2579f054933cbd9045e00cce79f95178.woff') format('woff'), /* chrome、firefox */
//        url('../fonts/2579f054933cbd9045e00cce79f95178.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//        url('../fonts/2579f054933cbd9045e00cce79f95178.svg#NeutraText-Demi') format('svg'); /* iOS 4.1- */
// }

@font-face {
  font-family: 'NeutraText-Book';
  font-display: swap;
  src: url('../fonts/90820e3d7b32d7491b62bd1aae2aafbe.eot'); /* IE9*/
  src: url('../fonts/90820e3d7b32d7491b62bd1aae2aafbe.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/90820e3d7b32d7491b62bd1aae2aafbe.woff2') format('woff2'), /* chrome、firefox */
  url('../fonts/90820e3d7b32d7491b62bd1aae2aafbe.woff') format('woff'), /* chrome、firefox */
  url('../fonts/90820e3d7b32d7491b62bd1aae2aafbe.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('../fonts/90820e3d7b32d7491b62bd1aae2aafbe.svg#NeutraText-Book') format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: 'Butler';
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Butler-Medium.otf');
}
