.o-contact {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;

  &.active {

    .o-contact__top,
    .o-contact__bottom {
      visibility: visible;
      opacity: 1;
    }
  }
}

.o-contact__inner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 0 $padding-xs;

  @include breakpoint($md) {
    margin: 0;
  }
}

.o-contact__top {
  margin-bottom: $padding-xl;
  transition-delay: 600ms !important;
}

.o-contact__bottom {
  transition-delay: 900ms !important;
}

.o-contact__logo {
  margin-bottom: $padding-md;
  max-width: 100%;

  @include breakpoint($md) {
    max-width: auto;
  }
}

.o-contact__text {
  margin-bottom: $padding-tiny;
}

.o-contact__email {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2.53px;

  &:hover {
    text-decoration: underline;
  }
}

.o-contact__bottom__text {
  font-family: $font;
  font-size: 1.1rem;
  letter-spacing: 1.83px;
  text-transform: uppercase;
}

.o-contact__top,
.o-contact__bottom {
  visibility: hidden;
  opacity: 0;
  transition: visibility .5s $easing, opacity .5s $easing;
}
