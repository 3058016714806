/*=============================================
=               Mixins - Layout               =
=============================================*/

/*----------  Breakpoints  ----------*/

@mixin breakpoint($point, $minOrMax: min-width) {
  @media ($minOrMax: $point) {
    @content;
  }
}
