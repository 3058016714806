/*=============================================
=                  Typograpy                  =
=============================================*/

// .h1 - .h6
@for $i from 1 through 6 {
  .h#{$i} {
    font-family: $mono;
    font-weight: 500;
  }
}

.h1 {
  font-size: 3.5rem;
  letter-spacing: 0.94px;
  line-height: 1.13;

  @include breakpoint($md) {
    font-size: 5.5rem;
  }

  @include breakpoint($lg) {
    font-size: 7rem;
  }

  @include breakpoint($xxxl) {
    font-size: 8rem;
  }
}

.h2 {
  font-size: 2.4rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.9rem;
}

.h5 {
  font-size: 1.8rem;
}

.h6 {
  font-size: 1.7rem;
}

.p1 {
  font-size: 1.3rem;
  letter-spacing: 0.17px;
  line-height: 1.53;

  @include breakpoint($md) {
    font-size: 1.5rem;
  }
}
