.o-nav {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $headerHeight;

  @include breakpoint($md) {
    width: 50%;
  }

  @include breakpoint($xxl) {
    width: 35%;
  }
}


.o-nav__logo {
  display: block;
  text-decoration: none;
  outline: 0;

  img {
    width: 4.5rem;
    height: auto;
    display: block;

    @include breakpoint($md) {
      width: 6rem;
    }
  }
}
