.o-two-columns-text {


  &.active {

    .o-two-columns-text__heading {
      visibility: visible;
      opacity: 1;
      top: 0;
    }

    .o-two-columns-text__text {
      visibility: visible;
      opacity: 1;
    }
  }

  .container,
  .row {
    height: 100%;
  }

  .row {
    flex-direction: column;
    justify-content: center;
  }

  @include breakpoint($md) {

    .row {
      flex-direction: row;
      align-items: center;
      justify-content: initial;
    }
  }
}

.o-two-columns-text__heading {
  margin-bottom: $padding-sm;
  text-transform: uppercase;
  transition-delay: 600ms !important;
  top: -25px;

  @include breakpoint($md) {
    margin: 0;
  }
}

.o-two-columns-text__text {
  transition-delay: 900ms !important;
}

.o-two-columns-text__heading,
.o-two-columns-text__text {
  position: relative;
  visibility: hidden;
  opacity: 0;
  transition: visibility .4s $easing, opacity .4s $easing, top .5s $easing;
}
