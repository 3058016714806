/*=============================================
=             Variables - Colors              =
=============================================*/

// Primary Colors
$white: #ffffff;
$black: #000000;

// Greys
$light-grey: #EFE7D4;
$grey: #807f8b;
$dark-grey: #37363a;

// Theme colors
$theme-color: #2a3c2b;
