/*=============================================
=                 Scope Base                  =
=============================================*/

.s-page {

  h1 {
    @extend .h1;
  }

  h2 {
    @extend .h2;
  }

  h3 {
    @extend .h3;
  }

  h4 {
    @extend .h4;
  }

  h5 {
    @extend .h5;
  }

  h6 {
    @extend .h6;
  }

  b, strong {
    font-weight: bold;
  }

  i, em {
    font-style: italic;
  }

  a {
    color: $theme-color;
    text-decoration: none;
    transition: color $time $easing;

    &:focus, &:hover {
      color: darken($theme-color, 10%);
      text-decoration: underline;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }

  ol {
    list-style-type: decimal;
    padding-left: 2rem;
  }

  blockquote {
    font-style: italic;
    quotes: "\201C""\201D""\2018""\2019";

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }
  }

  pre {
    font-family: $mono;
  }

}
