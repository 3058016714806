// body.is-loaded {

//   .l-header {
//     top: 3.5rem;
//   }
// }

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  // transition: top 2s ease-in;
}
