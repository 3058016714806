/*=============================================
=             Variables - Layout              =
=============================================*/

// Breakpoints
$tiny: 361px;
$xxs: 400px;
$xs: 481px;
$sm: 769px;
$md: 993px;
$lg: 1201px;
$xl: 1441px;
$xxl: 1701px;
$xxxl: 1921px;

// Default Grid Spacing
$grid-gutter-width: 20px;
$headerHeight: 7.5rem;
$footerHeight: 6rem;
$layoutGutter: 3rem;


// Default Padding
$padding-tiny: 0.8rem;
$padding-xxs: 1.6rem;
$padding-xs: 2.4rem;
$padding-sm: 3.2rem;
$padding-md: 4.8rem;
$padding-lg: 6.4rem;
$padding-xl: 9.6rem;
$padding-xxl: 12.8rem;

