.m-nav-list {
  visibility: hidden;
  opacity: 0;

  @include breakpoint($md) {
    visibility: visible;
    opacity: 1;
  }
}

.m-nav-list__items__item {
  position: relative;
  display: inline-block;
  margin-right: $padding-sm;

  &:last-child {
    margin-right: 0;
  }
}

.m-nav-list__items__item__link {
  text-decoration: none;
  letter-spacing: 2.53px;
  font-size: 1.2rem;
  position: relative;
  color: $black;
  text-transform: uppercase;
  line-height: 1;

  &::before {
    content: "";
    position: absolute;
    top: 30%;
    left: -10%;
    width: 0;
    height: 2px;
    background-color: $black;
    transition: width $time $easing;
  }

  &.is-active,
  &:hover {

    &::before {
      width: 120%;
    }
  }
}
